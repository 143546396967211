<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
            </div>
          </template>
        </Toolbar>
        <DataTable
          :value="this.users"
          :paginator="true"
          :rows="10"
          :loading="this.loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="{first} à {last} des {totalRecords} comptes utilisateurs"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0">Liste des utilisateurs</h5>
            </div>
          </template>
          <Column
            field="login"
            header="Identifiant"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              {{ slotProps.data.login }}
              <span class="p-column-title">Identifiant</span>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="createUserDialog"
          :style="{ width: '450px' }"
          header="Créer un utilisateur"
          :modal="true"
          class="p-fluid"
        >
          <form v-on:submit.prevent="createUser" ref="userForm">
            <ak-input-text
              required="true"
              v-model="this.user.login"
              label="Identifiant"
              :validator="v$.user.login"
              :submitted="this.submitted"
            ></ak-input-text>
            <ak-password
              required="true"
              v-model="this.user.password"
              label="Mot de passe"
              :validator="v$.user.password"
              :submitted="this.submitted"
              :feedback="false"
            ></ak-password>
            <div class="p-dialog-footer">
              <!-- Bouton pour valider le formulaire (il est différent des autres) -->
              <button class="p-button p-component p-button-text">
                <span
                  class="pi pi-check p-button-icon p-button-icon-left"
                ></span>
                <span class="p-button-label">Ajouter</span>
              </button>
            </div>
          </form>
        </Dialog>

        <Dialog
          v-model:visible="deleteUserDialog"
          :style="{ width: '450px' }"
          header="Confirmer"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="user"
              >Voulez-vous vraiment supprimer le user
              <b>{{ user.name }}</b> ?</span
            >
          </div>
          <div class="p-dialog-footer">
            <Button
              label="Non"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteUserDialog = false"
            />
            <Button
              label="Oui"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteUser"
            />
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/service/UserService";
import AkInputText from "@/components/input/AkInputText";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AkPassword from "@components/input/AkPassword";

export default {
  components: { AkInputText, AkPassword },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      users: [],
      createUserDialog: false,
      deleteUserDialog: false,
      user: {},
      filters: {},
      submitted: false,
      loading: true,
      statuses: [],
    };
  },

  validations() {
    return {
      user: {
        login: { required },
        password: { required },
      },
    };
  },

  userService: null,
  created() {
    this.userService = new UserService();
  },

  mounted() {
    console.log("mounted");
    this.userService.users().then((data) => {
      this.users = data;
      console.log(this.users);
      this.loading = false;
    });
  },
  methods: {
    openNew() {
      this.user = {};
      this.submitted = false;
      this.createUserDialog = true;
    },
    hideDialog() {
      this.createUserDialog = false;
      this.submitted = false;
    },

    createUser() {
      let _this = this;
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      this.userService.create(this.user).then((data) => {
        _this.user = data;
        this.users.push(this.user);
        this.$toast.add({
          severity: "success",
          summary: "Terminé",
          detail: this.user.login + " a bien été créé",
          life: 3000,
        });
      });
      this.createUserDialog = false;
      this.submitted = false;
    },

    editUser(user) {
      this.user = { ...user };
      this.createUserDialog = true;
    },
    confirmDeleteUser(user) {
      this.user = user;
      this.deleteUserDialog = true;
    },
    deleteUser() {
      this.users = this.users.filter((val) => val.id !== this.user.id);
      this.userService.delete(this.user);
      this.deleteUserDialog = false;
      this.user = {};
      this.$toast.add({
        severity: "success",
        summary: "Terminé",
        detail: "User supprimé",
        life: 3000,
      });
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].userId === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    goToRegisterPage(specId) {
      return "/public/user/" + specId + "/register";
      //route.push({name: "userRegister", params: { userId: specId }});
    },
  },
};
</script>
